import { render, staticRenderFns } from "./recommend-operation.vue?vue&type=template&id=0edcf169&scoped=true&"
import script from "./recommend-operation.vue?vue&type=script&lang=js&"
export * from "./recommend-operation.vue?vue&type=script&lang=js&"
import style0 from "./recommend-operation.vue?vue&type=style&index=0&id=0edcf169&lang=scss&scoped=true&"
import style1 from "./recommend-operation.vue?vue&type=style&index=1&id=0edcf169&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0edcf169",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0edcf169')) {
      api.createRecord('0edcf169', component.options)
    } else {
      api.reload('0edcf169', component.options)
    }
    module.hot.accept("./recommend-operation.vue?vue&type=template&id=0edcf169&scoped=true&", function () {
      api.rerender('0edcf169', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/component/workTable/recommend-operation.vue"
export default component.exports