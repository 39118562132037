var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "recommend-operation",
      class: _vm.isShrink ? "recommend-operation-shrink" : "",
    },
    [
      _vm.isShrink && _vm.recommend.status != 32
        ? _c(
            "el-dropdown",
            [
              (_vm.recommend.status != 0 && _vm.recommend.status != 2) ||
              _vm.recommend.isPublisher ||
              (_vm.recommend.status == 0 &&
                !_vm.recommend.isPublisher &&
                _vm.tableType == 1)
                ? _c("span", { staticClass: "el-dropdown-link name-cell" }, [
                    _c(
                      "span",
                      { staticClass: "operation-icon" },
                      [
                        _c("font-icon", {
                          staticClass:
                            "table-body-icon-big operation-icon-default tip-margin",
                          attrs: { href: "#icon-ic_act_grey" },
                        }),
                        _c("font-icon", {
                          staticClass:
                            "table-body-icon-big operation-icon-hover tip-margin",
                          attrs: { href: "#icon-ic_act_green" },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              (_vm.recommend.status != 0 && _vm.recommend.status != 2) ||
              _vm.recommend.isPublisher ||
              (_vm.recommend.status == 0 &&
                !_vm.recommend.isPublisher &&
                _vm.tableType == 1)
                ? _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "shrink-dropdown-menu",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    [
                      _vm.recommend.status == 0
                        ? [
                            _vm.recommend.isPublisher
                              ? [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.stage(
                                            _vm.recommend,
                                            1,
                                            "接受"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("接受")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.stage(
                                            _vm.recommend,
                                            2,
                                            "拒绝"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("拒绝")]
                                  ),
                                ]
                              : _vm._e(),
                            _vm.isCfUser && _vm.tableType == 1
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.businessClassficationEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑业务分类")]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.recommend.status == 2 && _vm.recommend.isPublisher
                        ? [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      1,
                                      "重新接受"
                                    )
                                  },
                                },
                              },
                              [_vm._v("重新接受")]
                            ),
                            _vm.isCfUser && _vm.tableType == 1
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.businessClassficationEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑业务分类")]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.recommend.status == 1
                        ? [
                            _vm.recommend.isCanRecommendToCustomer &&
                            !_vm.recommend.isRecommendToCustomer
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.stage(
                                          _vm.recommend,
                                          100,
                                          "推荐至客户"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("推荐至客户")]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      4,
                                      "进入面试"
                                    )
                                  },
                                },
                              },
                              [_vm._v("进入面试")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      10241,
                                      "淘汰"
                                    )
                                  },
                                },
                              },
                              [_vm._v("淘汰")]
                            ),
                            _vm.isCfUser && _vm.tableType == 1
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.businessClassficationEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑业务分类")]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.recommend.status == 64
                        ? [
                            !_vm.isUserInterviewConfirmed
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.stage(
                                          _vm.recommend,
                                          4,
                                          "确认进入面试"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("确认进入面试")]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      10241,
                                      "淘汰"
                                    )
                                  },
                                },
                              },
                              [_vm._v("淘汰")]
                            ),
                            _vm.isCfUser && _vm.tableType == 1
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.businessClassficationEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑业务分类")]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.recommend.status == 4 &&
                      (_vm.recommend.interviewConfirmedStatus === 1 ||
                        _vm.recommend.interviewConfirmedStatus === 2)
                        ? [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      5,
                                      "安排面试"
                                    )
                                  },
                                },
                              },
                              [_vm._v("安排面试")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      102440,
                                      "淘汰"
                                    )
                                  },
                                },
                              },
                              [_vm._v("淘汰")]
                            ),
                            _vm.isCfUser && _vm.tableType == 1
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.businessClassficationEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑业务分类")]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.recommend.status == 5 || _vm.recommend.status == 6
                        ? [
                            _c(
                              "el-dropdown-item",
                              {
                                staticClass: "text-green",
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      6,
                                      "反馈面试结果"
                                    )
                                  },
                                },
                              },
                              [_vm._v("反馈面试结果")]
                            ),
                            _vm.isCfUser && _vm.tableType == 1
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.businessClassficationEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑业务分类")]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.recommend.status == 407
                        ? [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      5,
                                      "安排下一轮"
                                    )
                                  },
                                },
                              },
                              [_vm._v("安排下一轮")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      407,
                                      "进入Offering中"
                                    )
                                  },
                                },
                              },
                              [_vm._v("进入Offering中")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      10244,
                                      "淘汰"
                                    )
                                  },
                                },
                              },
                              [_vm._v("淘汰")]
                            ),
                            _vm.isCfUser && _vm.tableType == 1
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.businessClassficationEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑业务分类")]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      [7, 9, 10, 11, 12, 13].includes(_vm.recommend.status)
                        ? [
                            [7, 9, 10, 11, 12].includes(_vm.recommend.status)
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.stage(
                                          _vm.recommend,
                                          _vm.recommend.status,
                                          "下一步"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("下一步")]
                                )
                              : _vm._e(),
                            ![3, 5].includes(_vm.recommend.jobType)
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.stage(
                                          _vm.recommend,
                                          8,
                                          "offer"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("offer")]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      10248,
                                      "淘汰"
                                    )
                                  },
                                },
                              },
                              [_vm._v("淘汰")]
                            ),
                            _vm.isCfUser && _vm.tableType == 1
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.businessClassficationEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑业务分类")]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.recommend.status == 8
                        ? [
                            ![3, 5].includes(_vm.recommend.jobType) ||
                            ([3, 5].includes(_vm.recommend.jobType) &&
                              !_vm.recommend.canCreateOfferInfo)
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.stage(
                                          _vm.recommend,
                                          16,
                                          "入职"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("入职")]
                                )
                              : _vm._e(),
                            _vm.perfEditBtnShow &&
                            !_vm.recommend.canCreateOfferInfo
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.performanceEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑业绩")]
                                )
                              : _vm._e(),
                            [3, 5].includes(_vm.recommend.jobType) &&
                            _vm.recommend.canCreateOfferInfo
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.performanceEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("填写业绩")]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      10248,
                                      "淘汰"
                                    )
                                  },
                                },
                              },
                              [_vm._v("淘汰")]
                            ),
                          ]
                        : _vm._e(),
                      _vm.recommend.status == 16
                        ? [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(_vm.recommend, 32, "离职")
                                  },
                                },
                              },
                              [_vm._v("离职")]
                            ),
                            _vm.perfEditBtnShow &&
                            !_vm.recommend.canCreateOfferInfo
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.performanceEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑业绩")]
                                )
                              : _vm._e(),
                            [3, 5].includes(_vm.recommend.jobType) &&
                            _vm.recommend.canCreateOfferInfo
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.performanceEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("填写业绩")]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.recommend.status == 1024
                        ? [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(
                                      _vm.recommend,
                                      1024,
                                      "恢复"
                                    )
                                  },
                                },
                              },
                              [_vm._v("恢复")]
                            ),
                          ]
                        : _vm._e(),
                      _vm.recommend.canInvoiceApplication &&
                      _vm.isPM &&
                      ![3, 5].includes(_vm.recommend.jobType)
                        ? [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.applyInvoice(_vm.recommend)
                                  },
                                },
                              },
                              [_vm._v("申请开票")]
                            ),
                          ]
                        : _vm._e(),
                      [24, 26].includes(_vm.recommend.status)
                        ? [
                            _vm.perfEditBtnShow &&
                            !_vm.recommend.canCreateOfferInfo
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.performanceEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑业绩")]
                                )
                              : _vm._e(),
                            [3, 5].includes(_vm.recommend.jobType) &&
                            _vm.recommend.canCreateOfferInfo
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.performanceEdit(
                                          _vm.recommend
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("填写业绩")]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.stage(_vm.recommend, 32, "离职")
                                  },
                                },
                              },
                              [_vm._v("离职")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : [
            _vm.recommend.status == 0
              ? [
                  _vm.recommend.isPublisher
                    ? [
                        _c(
                          "span",
                          {
                            staticClass: "text-green",
                            on: {
                              click: function ($event) {
                                return _vm.stage(_vm.recommend, 1, "接受")
                              },
                            },
                          },
                          [_vm._v("接受")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "text-orange",
                            on: {
                              click: function ($event) {
                                return _vm.stage(_vm.recommend, 2, "拒绝")
                              },
                            },
                          },
                          [_vm._v("拒绝")]
                        ),
                      ]
                    : _vm._e(),
                  _vm.isCfUser && _vm.tableType == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.businessClassficationEdit(
                                _vm.recommend
                              )
                            },
                          },
                        },
                        [_vm._v("编辑业务分类")]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.recommend.status == 2 && _vm.recommend.isPublisher
              ? [
                  _c(
                    "span",
                    {
                      staticClass: "text-green",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 1, "重新接受")
                        },
                      },
                    },
                    [_vm._v("重新接受")]
                  ),
                  _vm.isCfUser && _vm.tableType == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.businessClassficationEdit(
                                _vm.recommend
                              )
                            },
                          },
                        },
                        [_vm._v("编辑业务分类")]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.recommend.status == 1
              ? [
                  _vm.recommend.isCanRecommendToCustomer &&
                  !_vm.recommend.isRecommendToCustomer
                    ? _c(
                        "span",
                        {
                          staticClass: "text-blue",
                          on: {
                            click: function ($event) {
                              return _vm.stage(_vm.recommend, 100, "推荐至客户")
                            },
                          },
                        },
                        [_vm._v("推荐至客户")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "text-green",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 4, "进入面试")
                        },
                      },
                    },
                    [_vm._v("进入面试")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "text-orange",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 10241, "淘汰")
                        },
                      },
                    },
                    [_vm._v("淘汰")]
                  ),
                  _vm.isCfUser && _vm.tableType == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.businessClassficationEdit(
                                _vm.recommend
                              )
                            },
                          },
                        },
                        [_vm._v("编辑业务分类")]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.recommend.status == 64
              ? [
                  !_vm.isUserInterviewConfirmed
                    ? _c(
                        "span",
                        {
                          staticClass: "text-green",
                          on: {
                            click: function ($event) {
                              return _vm.stage(_vm.recommend, 4, "确认进入面试")
                            },
                          },
                        },
                        [_vm._v("确认进入面试")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "text-orange",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 10241, "淘汰")
                        },
                      },
                    },
                    [_vm._v("淘汰")]
                  ),
                  _vm.isCfUser && _vm.tableType == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.businessClassficationEdit(
                                _vm.recommend
                              )
                            },
                          },
                        },
                        [_vm._v("编辑业务分类")]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.recommend.status == 4 &&
            (_vm.recommend.interviewConfirmedStatus === 1 ||
              _vm.recommend.interviewConfirmedStatus === 2)
              ? [
                  _c(
                    "span",
                    {
                      staticClass: "text-green",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 5, "安排面试")
                        },
                      },
                    },
                    [_vm._v("安排面试")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "text-orange",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 102440, "淘汰")
                        },
                      },
                    },
                    [_vm._v("淘汰")]
                  ),
                  _vm.isCfUser && _vm.tableType == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.businessClassficationEdit(
                                _vm.recommend
                              )
                            },
                          },
                        },
                        [_vm._v("编辑业务分类")]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.recommend.status == 5 || _vm.recommend.status == 6
              ? [
                  _c(
                    "span",
                    {
                      staticClass: "text-green",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 6, "反馈面试结果")
                        },
                      },
                    },
                    [_vm._v("反馈面试结果")]
                  ),
                  _vm.isCfUser && _vm.tableType == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.businessClassficationEdit(
                                _vm.recommend
                              )
                            },
                          },
                        },
                        [_vm._v("编辑业务分类")]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.recommend.status == 407
              ? [
                  _c(
                    "span",
                    {
                      staticClass: "text-green",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 5, "安排下一轮")
                        },
                      },
                    },
                    [_vm._v("安排下一轮")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "text-green",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 407, "进入Offering中")
                        },
                      },
                    },
                    [_vm._v("进入Offering中")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "text-orange",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 10244, "淘汰")
                        },
                      },
                    },
                    [_vm._v("淘汰")]
                  ),
                  _vm.isCfUser && _vm.tableType == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.businessClassficationEdit(
                                _vm.recommend
                              )
                            },
                          },
                        },
                        [_vm._v("编辑业务分类")]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            [7, 9, 10, 11, 12, 13].includes(_vm.recommend.status)
              ? [
                  [7, 9, 10, 11, 12].includes(_vm.recommend.status)
                    ? _c(
                        "span",
                        {
                          staticClass: "text-green",
                          on: {
                            click: function ($event) {
                              return _vm.stage(
                                _vm.recommend,
                                _vm.recommend.status,
                                "下一步"
                              )
                            },
                          },
                        },
                        [_vm._v("下一步")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "text-green",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 8, "offer")
                        },
                      },
                    },
                    [_vm._v("offer")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "text-orange",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 10248, "淘汰")
                        },
                      },
                    },
                    [_vm._v("淘汰")]
                  ),
                  _vm.isCfUser && _vm.tableType == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.businessClassficationEdit(
                                _vm.recommend
                              )
                            },
                          },
                        },
                        [_vm._v("编辑业务分类")]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.recommend.status == 8
              ? [
                  ![3, 5].includes(_vm.recommend.jobType) ||
                  ([3, 5].includes(_vm.recommend.jobType) &&
                    !_vm.recommend.canCreateOfferInfo)
                    ? _c(
                        "span",
                        {
                          staticClass: "text-green",
                          on: {
                            click: function ($event) {
                              return _vm.stage(_vm.recommend, 16, "入职")
                            },
                          },
                        },
                        [_vm._v("入职")]
                      )
                    : _vm._e(),
                  ![3, 5].includes(_vm.recommend.jobType) &&
                  _vm.perfEditBtnShow &&
                  !_vm.recommend.canCreateOfferInfo
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.performanceEdit(_vm.recommend)
                            },
                          },
                        },
                        [_vm._v("编辑业绩")]
                      )
                    : _vm._e(),
                  [3, 5].includes(_vm.recommend.jobType) &&
                  _vm.recommend.canCreateOfferInfo
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.performanceEdit(_vm.recommend)
                            },
                          },
                        },
                        [_vm._v("填写业绩")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "text-orange",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 10248, "淘汰")
                        },
                      },
                    },
                    [_vm._v("淘汰")]
                  ),
                ]
              : _vm._e(),
            _vm.recommend.status == 16
              ? [
                  _c(
                    "span",
                    {
                      staticClass: "text-orange",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 32, "离职")
                        },
                      },
                    },
                    [_vm._v("离职")]
                  ),
                  _vm.perfEditBtnShow && !_vm.recommend.canCreateOfferInfo
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.performanceEdit(_vm.recommend)
                            },
                          },
                        },
                        [_vm._v("编辑业绩")]
                      )
                    : _vm._e(),
                  [3, 5].includes(_vm.recommend.jobType) &&
                  _vm.recommend.canCreateOfferInfo
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.performanceEdit(_vm.recommend)
                            },
                          },
                        },
                        [_vm._v("填写业绩")]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.recommend.status == 1024
              ? [
                  _c(
                    "span",
                    {
                      staticClass: "text-green",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 1024, "恢复")
                        },
                      },
                    },
                    [_vm._v("恢复")]
                  ),
                ]
              : _vm._e(),
            _vm.recommend.canInvoiceApplication &&
            _vm.isPM &&
            ![3, 5].includes(_vm.recommend.jobType)
              ? [
                  _c(
                    "span",
                    {
                      staticClass: "text-green",
                      on: {
                        click: function ($event) {
                          return _vm.applyInvoice(_vm.recommend)
                        },
                      },
                    },
                    [_vm._v("申请开票")]
                  ),
                ]
              : _vm._e(),
            [24, 26].includes(_vm.recommend.status)
              ? [
                  _vm.perfEditBtnShow && !_vm.recommend.canCreateOfferInfo
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.performanceEdit(_vm.recommend)
                            },
                          },
                        },
                        [_vm._v("编辑业绩")]
                      )
                    : _vm._e(),
                  [3, 5].includes(_vm.recommend.jobType) &&
                  _vm.recommend.canCreateOfferInfo
                    ? _c(
                        "span",
                        {
                          staticClass: "text-grey",
                          on: {
                            click: function ($event) {
                              return _vm.performanceEdit(_vm.recommend)
                            },
                          },
                        },
                        [_vm._v("填写业绩")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "text-orange",
                      on: {
                        click: function ($event) {
                          return _vm.stage(_vm.recommend, 32, "离职")
                        },
                      },
                    },
                    [_vm._v("离职")]
                  ),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }