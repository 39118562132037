<template>
    <div class="recommend-operation" :class="isShrink ? 'recommend-operation-shrink' : ''" v-loading="loading">
        <el-dropdown v-if="isShrink && recommend.status != 32">
            <!-- <span class="el-dropdown-link name-cell" v-if="(recommend.status != 0 && recommend.status != 2) || recommend.isPublisher"> -->
            <span class="el-dropdown-link name-cell" v-if="(recommend.status != 0 && recommend.status != 2) || recommend.isPublisher || (recommend.status == 0 && !recommend.isPublisher && tableType == 1)">
                <span class="operation-icon">
                    <font-icon class="table-body-icon-big operation-icon-default tip-margin" href="#icon-ic_act_grey"></font-icon>
                    <font-icon class="table-body-icon-big operation-icon-hover tip-margin" href="#icon-ic_act_green"></font-icon>
                </span>
            </span>
            <!-- <el-dropdown-menu class="shrink-dropdown-menu" slot="dropdown" v-if="(recommend.status != 0 && recommend.status != 2) || recommend.isPublisher"> -->
            <el-dropdown-menu class="shrink-dropdown-menu" slot="dropdown" v-if="(recommend.status != 0 && recommend.status != 2) || recommend.isPublisher || (recommend.status == 0 && !recommend.isPublisher && tableType == 1)">
                <!-- 未处理或推荐待反馈状态 -->
                <!-- <template v-if="recommend.status == 0 && recommend.isPublisher">
                    <el-dropdown-item @click.native="stage(recommend, 1, '接受')">接受</el-dropdown-item>
                    <el-dropdown-item @click.native="stage(recommend, 2, '拒绝')">拒绝</el-dropdown-item>
                </template> -->
                <template v-if="recommend.status == 0">
                    <template v-if="recommend.isPublisher">
                        <el-dropdown-item @click.native="stage(recommend, 1, '接受')">接受</el-dropdown-item>
                        <el-dropdown-item @click.native="stage(recommend, 2, '拒绝')">拒绝</el-dropdown-item>
                    </template>
                    <el-dropdown-item @click.native="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</el-dropdown-item>
                </template>
                <!-- 推荐拒绝状态 -->
                <template v-if="recommend.status == 2 && recommend.isPublisher">
                    <el-dropdown-item @click.native="stage(recommend, 1, '重新接受')">重新接受</el-dropdown-item>
                    <el-dropdown-item @click.native="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</el-dropdown-item>
                </template>
                <!-- 已接受待推进面试状态 -->
                <template v-if="recommend.status == 1">
                    <el-dropdown-item @click.native="stage(recommend, 100, '推荐至客户')" v-if="recommend.isCanRecommendToCustomer && !recommend.isRecommendToCustomer">推荐至客户</el-dropdown-item>
                    <!-- <el-dropdown-item @click.native="stage(recommend, 88, '一键offer')" v-if="recommend.canOnekeyOffer">一键offer</el-dropdown-item> -->
                    <el-dropdown-item @click.native="stage(recommend, 4, '进入面试')">进入面试</el-dropdown-item>
                    <el-dropdown-item @click.native="stage(recommend, 10241, '淘汰')">淘汰</el-dropdown-item>
                    <el-dropdown-item @click.native="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</el-dropdown-item>
                </template>
                <template v-if="recommend.status == 64">
                    <el-dropdown-item v-if="!isUserInterviewConfirmed" @click.native="stage(recommend, 4, '确认进入面试')">确认进入面试</el-dropdown-item>
                    <el-dropdown-item @click.native="stage(recommend, 10241, '淘汰')">淘汰</el-dropdown-item>
                    <el-dropdown-item @click.native="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</el-dropdown-item>
                </template>
                <!-- 面试待安排状态 -->
                <template v-if="recommend.status == 4 && (recommend.interviewConfirmedStatus === 1 || recommend.interviewConfirmedStatus === 2)">
                    <!-- <el-dropdown-item @click.native="stage(recommend, 88, '一键offer')" v-if="recommend.canOnekeyOffer">一键offer</el-dropdown-item> -->
                    <el-dropdown-item @click.native="stage(recommend, 5, '安排面试')">安排面试</el-dropdown-item>
                    <el-dropdown-item @click.native="stage(recommend, 102440, '淘汰')">淘汰</el-dropdown-item>
                    <el-dropdown-item @click.native="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</el-dropdown-item>
                </template>
                <!-- 面试已安排及面试待评估状态 -->
                <template v-if="recommend.status == 5 || recommend.status == 6">
                    <!-- <el-dropdown-item @click.native="stage(recommend, 88, '一键offer')" v-if="recommend.canOnekeyOffer">一键offer</el-dropdown-item> -->
                    <el-dropdown-item class="text-green" 
                        @click.native="stage(recommend, 6, '反馈面试结果')"
                        >反馈面试结果</el-dropdown-item>
                    <el-dropdown-item @click.native="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</el-dropdown-item>
                </template>
                <!-- 面试已反馈状态 -->
                <template v-if="recommend.status == 407">
                    <el-dropdown-item @click.native="stage(recommend, 5, '安排下一轮')">安排下一轮</el-dropdown-item>
                    <el-dropdown-item @click.native="stage(recommend, 407, '进入Offering中')">进入Offering中</el-dropdown-item>
                    <el-dropdown-item @click.native="stage(recommend, 10244, '淘汰')">淘汰</el-dropdown-item>
                    <el-dropdown-item @click.native="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</el-dropdown-item>
                </template>
                <!-- Offer状态 -->
                <template v-if="[7, 9, 10, 11, 12, 13].includes(recommend.status)">
                    <el-dropdown-item 
                        v-if="[7, 9, 10, 11, 12].includes(recommend.status)" 
                        @click.native="stage(recommend, recommend.status, '下一步')"
                        >下一步</el-dropdown-item>
                    <!-- <el-dropdown-item @click.native="stage(recommend, 16, '入职')" v-if="recommend.jobType != 3 || (recommend.jobType == 3 && !recommend.canCreateOfferInfo)">入职</el-dropdown-item> -->
                    
                    <el-dropdown-item v-if="![3, 5].includes(recommend.jobType)" @click.native="stage(recommend, 8, 'offer')">offer</el-dropdown-item>
                    <el-dropdown-item @click.native="stage(recommend, 10248, '淘汰')">淘汰</el-dropdown-item>
                    <!-- <el-dropdown-item @click.native="performanceEdit(recommend)" v-if="perfEditBtnShow && !recommend.canCreateOfferInfo">编辑业绩</el-dropdown-item> -->
                    <!-- <el-dropdown-item @click.native="performanceEdit(recommend)" v-if="recommend.jobType == 3 && recommend.canCreateOfferInfo">填写业绩</el-dropdown-item> -->
                    <el-dropdown-item @click.native="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</el-dropdown-item>
                </template>
                
                <!-- 已Offer -->
                <template v-if="recommend.status == 8">
                    <el-dropdown-item @click.native="stage(recommend, 16, '入职')" v-if="![3, 5].includes(recommend.jobType) || ([3, 5].includes(recommend.jobType) && !recommend.canCreateOfferInfo)">入职</el-dropdown-item>
                    <el-dropdown-item @click.native="performanceEdit(recommend)" v-if="perfEditBtnShow && !recommend.canCreateOfferInfo">编辑业绩</el-dropdown-item>
                    <el-dropdown-item @click.native="performanceEdit(recommend)" v-if="[3, 5].includes(recommend.jobType) && recommend.canCreateOfferInfo">填写业绩</el-dropdown-item>
                    <el-dropdown-item @click.native="stage(recommend, 10248, '淘汰')">淘汰</el-dropdown-item>
                </template>

                <!-- 入职状态 -->
                <template v-if="recommend.status == 16">
                    <el-dropdown-item @click.native="stage(recommend, 32, '离职')">离职</el-dropdown-item>
                    <el-dropdown-item @click.native="performanceEdit(recommend)" v-if="perfEditBtnShow && !recommend.canCreateOfferInfo">编辑业绩</el-dropdown-item>
                    <el-dropdown-item @click.native="performanceEdit(recommend)" v-if="[3, 5].includes(recommend.jobType) && recommend.canCreateOfferInfo">填写业绩</el-dropdown-item>
                </template>
                <!-- 淘汰状态 -->
                <template v-if="recommend.status == 1024">
                    <el-dropdown-item @click.native="stage(recommend, 1024, '恢复')">恢复</el-dropdown-item>
                </template>
                <!-- 离职状态无按钮 -->
                <template v-if="recommend.canInvoiceApplication && isPM && ![3, 5].includes(recommend.jobType)">
                    <el-dropdown-item @click.native="applyInvoice(recommend)">申请开票</el-dropdown-item>
                </template>
                <template v-if="[24, 26].includes(recommend.status)">
                    <el-dropdown-item @click.native="performanceEdit(recommend)" v-if="perfEditBtnShow && !recommend.canCreateOfferInfo">编辑业绩</el-dropdown-item>
                    <el-dropdown-item @click.native="performanceEdit(recommend)" v-if="[3, 5].includes(recommend.jobType) && recommend.canCreateOfferInfo">填写业绩</el-dropdown-item>
                    <el-dropdown-item @click.native="stage(recommend, 32, '离职')" >离职</el-dropdown-item>
                </template>
            </el-dropdown-menu>
        </el-dropdown>
        <template v-else>
            <!-- 
                编辑业务分类操作按钮在我的推荐报表（tableType == 1）中存在且只有已offer之前才可以操作编辑
             -->
            <!-- 未处理或推荐待反馈状态 -->
            <!-- <template v-if="recommend.status == 0 && recommend.isPublisher">
                <span class="text-green" @click="stage(recommend, 1, '接受')">接受</span>
                <span class="text-orange" @click="stage(recommend, 2, '拒绝')">拒绝</span>
            </template> -->
            <template v-if="recommend.status == 0">
                <template v-if="recommend.isPublisher">
                    <span class="text-green" @click="stage(recommend, 1, '接受')">接受</span>
                    <span class="text-orange" @click="stage(recommend, 2, '拒绝')">拒绝</span>
                </template>
                <span class="text-grey" @click="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</span>
            </template>
            <!-- 推荐拒绝状态 -->
            <template v-if="recommend.status == 2 && recommend.isPublisher">
                <span class="text-green" @click="stage(recommend, 1, '重新接受')">重新接受</span>
                <span class="text-grey" @click="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</span>
            </template>
            <!-- 已接受待推进面试状态 -->
            <template v-if="recommend.status == 1">
                <span class="text-blue" @click="stage(recommend, 100, '推荐至客户')" v-if="recommend.isCanRecommendToCustomer && !recommend.isRecommendToCustomer">推荐至客户</span>
                <!-- <span class="text-green" @click="stage(recommend, 88, '一键offer')" v-if="recommend.canOnekeyOffer">一键offer</span> -->
                <span class="text-green" @click="stage(recommend, 4, '进入面试')">进入面试</span>
                <span class="text-orange" @click="stage(recommend, 10241, '淘汰')">淘汰</span>
                <span class="text-grey" @click="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</span>
            </template>
            <!-- 面试待确认状态 -->
            <template v-if="recommend.status == 64">
                <span class="text-green" v-if="!isUserInterviewConfirmed" @click="stage(recommend, 4, '确认进入面试')">确认进入面试</span>
                <span class="text-orange" @click="stage(recommend, 10241, '淘汰')">淘汰</span>
                <span class="text-grey" @click="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</span>
            </template>
            <!-- 面试待安排状态 -->
            <template v-if="recommend.status == 4 && (recommend.interviewConfirmedStatus === 1 || recommend.interviewConfirmedStatus === 2)">
                <!-- <span class="text-green" @click="stage(recommend, 88, '一键offer')" v-if="recommend.canOnekeyOffer">一键offer</span> -->
                <span class="text-green" @click="stage(recommend, 5, '安排面试')">安排面试</span>
                <span class="text-orange" @click="stage(recommend, 102440, '淘汰')">淘汰</span>
                <span class="text-grey" @click="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</span>
            </template>
            <!-- 面试已安排状态 -->
            <template v-if="recommend.status == 5 || recommend.status == 6">
                <!-- <span class="text-green" @click="stage(recommend, 88, '一键offer')" v-if="recommend.canOnekeyOffer">一键offer</span> -->
                <span class="text-green" @click="stage(recommend, 6, '反馈面试结果')">反馈面试结果</span>
                <span class="text-grey" @click="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</span>
            </template>
            <!-- 面试已反馈状态 -->
            <template v-if="recommend.status == 407">
                <span class="text-green" @click="stage(recommend, 5, '安排下一轮')">安排下一轮</span>
                 <!-- v-if="recommend.jobType!=3" -->
                <!-- <span class="text-green" @click="stage(recommend, 8, 'offer')">offer</span> -->
                <span class="text-green" @click="stage(recommend, 407, '进入Offering中')">进入Offering中</span>
                <span class="text-orange" @click="stage(recommend, 10244, '淘汰')">淘汰</span>
                <span class="text-grey" @click="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</span>
            </template>
            <!-- Offer状态 -->
            <!-- v-if="recommend.status == 8" -->
            <!-- <template v-if="[8, 9, 10, 11, 12, 13].includes(recommend.status)"> -->
            <template v-if="[7, 9, 10, 11, 12, 13].includes(recommend.status)">
                <!-- <i 
                    class="text-orange" 
                    style="margin-right:5px;" 
                    v-if="[3, 5].includes(recommend.jobType)"
                    >*猎头尚未确认offer</i> -->
                <span class="text-green" 
                    v-if="[7, 9, 10, 11, 12].includes(recommend.status)" 
                    @click="stage(recommend, recommend.status, '下一步')"
                >下一步</span>
                <!-- <span class="text-green" @click="stage(recommend, 16, '入职')" v-if="recommend.jobType != 3 || (recommend.jobType == 3 && !recommend.canCreateOfferInfo)">入职</span> -->
                <!-- <span class="text-orange" @click="offer">offer  这里用以前的  </span> -->
                 <!-- v-if="![3, 5].includes(recommend.jobType)" -->
                <span class="text-green" @click="stage(recommend, 8, 'offer')">offer</span>
                <span class="text-orange" @click="stage(recommend, 10248, '淘汰')">淘汰</span>
                <!-- <span class="text-grey" @click="performanceEdit(recommend)" v-if="perfEditBtnShow && !recommend.canCreateOfferInfo">编辑业绩</span> -->
                <!-- <span class="text-grey" @click="performanceEdit(recommend)" v-if="recommend.jobType == 3 && recommend.canCreateOfferInfo">填写业绩</span> -->
                <span class="text-grey" @click="businessClassficationEdit(recommend)" v-if="isCfUser && tableType == 1">编辑业务分类</span>
            </template>

            <!-- 已Offer -->
            <template v-if="recommend.status == 8">
                <span class="text-green" @click="stage(recommend, 16, '入职')" v-if="![3, 5].includes(recommend.jobType) || ([3, 5].includes(recommend.jobType) && !recommend.canCreateOfferInfo)">入职</span>
                <span class="text-grey" @click="performanceEdit(recommend)" v-if="![3, 5].includes(recommend.jobType) && perfEditBtnShow && !recommend.canCreateOfferInfo">编辑业绩</span>
                <span class="text-grey" @click="performanceEdit(recommend)" v-if="[3, 5].includes(recommend.jobType) && recommend.canCreateOfferInfo">填写业绩</span>
                <span class="text-orange" @click="stage(recommend, 10248, '淘汰')">淘汰</span>
            </template>

            <!-- 入职状态 -->
            <template v-if="recommend.status == 16">
                <span class="text-orange" @click="stage(recommend, 32, '离职')">离职</span>
                <span class="text-grey" @click="performanceEdit(recommend)" v-if="perfEditBtnShow && !recommend.canCreateOfferInfo">编辑业绩</span>
                <span class="text-grey" @click="performanceEdit(recommend)" v-if="[3, 5].includes(recommend.jobType) && recommend.canCreateOfferInfo">填写业绩</span>
            </template>
            <!-- 淘汰状态 -->
            <template v-if="recommend.status == 1024">
                <span class="text-green" @click="stage(recommend, 1024, '恢复')">恢复</span>
            </template>
            <!-- 离职/淘汰状态无按钮 -->

            <!-- [Display(Name = "发票开票中")]
            InvoiceCreated = 24,
            [Display(Name = "发票已回款")]
            FundReceived = 26, -->
            <!-- 发票开票中/发票已回款 -->
            <!-- && recommend.status == 16 -->
            <template v-if="recommend.canInvoiceApplication && isPM && ![3, 5].includes(recommend.jobType)">
                <span class="text-green" @click="applyInvoice(recommend)">申请开票</span>
            </template>
            <template v-if="[24, 26].includes(recommend.status)">
                <span class="text-grey" @click="performanceEdit(recommend)" v-if="perfEditBtnShow && !recommend.canCreateOfferInfo">编辑业绩</span>
                <span class="text-grey" @click="performanceEdit(recommend)" v-if="[3, 5].includes(recommend.jobType) && recommend.canCreateOfferInfo">填写业绩</span>
                <span class="text-orange" @click="stage(recommend, 32, '离职')">离职</span>
            </template>
            
        </template>
    </div>
</template>

<script>
import pinganService from '#/js/service/pinganService';
import floatingListService from '#/js/service/floatingListService.js';

export default {
    props: {
        tableType: Number,
        isShrink: {
            //是否是缩略样式
            type: Boolean,
            default: false
        },
        recommend: Object,  //候选人信息
        index: 0,
        recommendPosition: '', //点击按钮的位置，表格行or展开行
    },
    components: {
    },
    computed: {
        perfEditBtnShow() {
            return this.recommend.isPublisher || (this.recommend.isRecommender && this.recommend.publishFirmId && this.recommend.publishFirmId !== this.$store.state.user.userInfo.firmId);
        },
        isUserInterviewConfirmed() {
            /*
             * interviewConfirmedStatus: 0-双方都未确认-None 1-自动确认-Automatic(历史数据) 2-双向确认-Double 3-职位发布者确认-JobOwnerConfirmed 4-推荐者确认-RecommenderConfirmed
             * 如果即是推荐者又是发布者，则设为未确认
            */
            if(!(this.recommend.isPublisher && this.recommend.isRecommender) && (this.recommend.interviewConfirmedStatus === 1 || (this.recommend.interviewConfirmedStatus === 3 && this.recommend.isPublisher) || (this.recommend.interviewConfirmedStatus === 4 && this.recommend.isRecommender))) {
                return true;
            }
            return false;
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isCfUser() {
            return this.$store.state.user.userInfo.isCFUser;
        },
        isPM () {
            return this.userInfo.privilegeCodeList.includes('Talent') || 
                this.userInfo.privilegeCodeList.includes('Job');
        },
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        businessClassficationEdit(recommend) {
            this.$emit('show-business-classfication-dialog', recommend);
        },
        async stage(recommend, nextStatus, btnText) {
            if(nextStatus === 1){
                await floatingListService
                    .checkCandidate([recommend.id])
                    .then(res => {
                        recommend.isShowMoreText = false;
                        if(res.length > 0){
                            recommend.isShowMoreText = true;
                        }
                    })
            }
            if(nextStatus === 100) {
                // 推荐给客户
                this.loading = true;
                _tracker.track('RecommendOperation', JSON.stringify({
                    tableName: window._pageCode ? window._pageCode.replace('FloatingList', '') : '',
                    recommendId: recommend.id,
                    position: this.recommendPosition,
                    text: btnText
                }));

                pinganService.recommendCustomer(recommend.id)
                    .then(res => {
                        this.loading = false;
                        if(res) {
                            if(res.recommendationStatus === 3) {
                                shortTips('推荐成功');
                            }else if(res.recommendationStatus === 2) {
                                shortTips('推荐失败，请再次推荐');
                            }else if(res.recommendationStatus === 1) {
                                shortTips('候选人查重，不能推荐给客户');
                            }
                            this.$emit('show-dialog', this.$props.index, nextStatus, recommend, btnText);
                            if(res.recommendationUrl) {
                                window.open(res.recommendationUrl);
                            }
                        }
                    }).catch(err => {
                        this.loading = false;
                        console.log(err);
                    })
            } else {
                let _nextStatus = nextStatus;
                if([9, 10, 11, 12].includes(nextStatus)){
                    _nextStatus = nextStatus + 1;
                }
                if(7 == nextStatus) {
                    _nextStatus = nextStatus + 2;
                }
                this.$emit('show-dialog', this.$props.index, _nextStatus, recommend, btnText);
            }
        },
        performanceEdit(recommend) {
            this.$emit('show-performance-dialog', this.$props.index, recommend);
        },
        applyInvoice(recommend) {
            console.log(recommend)
            const recommendId = recommend.id,
                jobOwnerId = recommend.jobOwnerId;
            // window.open(`/Headhunting/MyCompany.html#/applyFormPage?recommendId=${recommendId}&jobOwnerId=${jobOwnerId}`);
            window.open(`/#/applyFormPage?recommendId=${recommendId}&jobOwnerId=${jobOwnerId}`);
        },

    }
}
</script>

<style lang="scss" scoped>
    .recommend-operation {
        margin-left: 10px;
        font-size: 14px;
        line-height: 19px;
        & > span {
            cursor: pointer;
            position: relative;
            margin-right: 14px;
            &::after {
                content: "";
                width: 2px;
                height: 14px;
                display: inline-block;
                position: absolute;
                right: -8px;
                top: 3px;
                background-color: #ccc;
            }
            &:nth-last-of-type(1) {
                margin-right: 0;
                &::after {
                    display: none;
                }
            }
            &:hover {
                text-decoration: underline;
            }
        }
        .text-green {
            color: $primary;
        }
        .text-orange {
            color: #fc7859;
        }
        .text-blue {
            color: #4d91e0;
        }
        .text-grey {
            color: #999;
        }
    }
    .recommend-operation-shrink {
        font-size: 12px;
        line-height: 32px;
        color: #666;
        position: relative;
        text-align: center;
        width: 80px;
        margin-left: 0;
    }
</style>

<style lang="scss">
    .shrink-dropdown-menu {
        &.el-dropdown-menu.el-popper {
            width: 80px;
            margin: 0;
            padding: 0;
            text-align: center;
            top: 220px;
        }
        .el-dropdown-menu__item {
            font-size: 12px;
            color: #666;
            padding: 0;
        }
        .popper__arrow {
            display: none;
        }
    }
</style>
