var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("acceptDialog", {
        ref: "acceptDialog",
        on: { successFn: _vm.stageSuccess },
      }),
      _c("rejectDialog", {
        ref: "rejectDialog",
        on: { successFn: _vm.stageSuccess },
      }),
      _c("offerDialog", {
        ref: "offerDialog",
        on: {
          successFn: _vm.stageSuccess,
          successEditOffer: _vm.editOfferSuccess,
        },
      }),
      _c("interviewDialog", {
        ref: "interviewDialog",
        on: { successFn: _vm.stageSuccess },
      }),
      _c("obsoleteDialog", {
        ref: "obsoleteDialog",
        on: { successFn: _vm.stageSuccess },
      }),
      _c("processDialog", {
        ref: "processDialog",
        on: { successFn: _vm.stageSuccess },
      }),
      _c("stageDialog", {
        ref: "stageDialog",
        attrs: { status: _vm.stageStatus },
        on: { successFn: _vm.stageSuccess },
      }),
      _c("enter-sure-interview-dialog", {
        ref: "enterSureInterviewDialog",
        on: { successFn: _vm.stageSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }